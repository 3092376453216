<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  required,
} from "vuelidate/lib/validators";


/**
 * SMTP Settings component
 */
export default {
  page: {
    title: "Configurações de SMTP",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Configurações de SMTP",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Configurações de SMTP",
          active: true,
        },
      ],    
      form: {
        protocol: "",
        host: "",
        port: "",
        user: "",
        password: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      user: { required },
      password: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row">      
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Configurações de SMTP</h4>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Protocolo</label>                    
                    <input
                      v-model="form.protocol"
                      type="text"
                      class="form-control"
                      placeholder="Insira o protocolo SMTP"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Host SMTP</label>                    
                    <input
                      v-model="form.host"
                      type="text"
                      class="form-control"
                      placeholder="Insira o host SMTP"
                      name="host"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Porta SMTP</label>                    
                    <input
                      v-model="form.port"
                      type="text"
                      class="form-control"
                      placeholder="Insira a porta SMTP"
                      name="port"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Usuário SMTP <span class="required">*</span></label>                    
                    <input
                      v-model="form.user"
                      type="text"
                      class="form-control"
                      placeholder="Insira o usuário SMTP: example@example.com"
                      name="user"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Senha SMTP <span class="required">*</span></label>                    
                    <input
                      v-model="form.password"
                      type="password"
                      class="form-control"
                      placeholder="Insira a senha SMTP"
                      name="password"
                    />
                  </div>
                </div>
              </div>              
              <div class="row">
                <div class="col-12 col-md-3">
                  <button class="btn btn-primary w-100" type="submit">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
